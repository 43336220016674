import { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import LoaderComponenet from '../components/common/Loader';


import { PrivateRoute } from './PrivateRoute';

const Login = lazy(() => import('../components/Auth/Login'));
const Welcome = lazy(() => import('../components/Auth/Welcome'));
const RequestToken = lazy(() => import('../components/Auth/ResetPassword/RequestToken'));
const ResetPassword = lazy(() => import('../components/Auth/ResetPassword'));

const NotFound = lazy(() => import('../components/common/404'));

const Dashboard = lazy(() => import('../components/Dashboard'));

const RouteSetup = () => {

  const [loading, setLoading] = useState(false)
  const user = localStorage.getItem('user')
  const navigate = useNavigate()
  useEffect(() => {
    if (localStorage.getItem('token') === null && (window.location.pathname !== '/welcome' && window.location.pathname !== '/reset-password')) {
      setLoading(false)
      navigate('/login')
    }
    if (user || window.location.pathname === '/welcome' || window.location.pathname === '/reset-password') setLoading(false)
  }, [user])

  return (loading ?
    <LoaderComponenet /> :
    <Suspense fallback={<LoaderComponenet />}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/reset-password" element={<RequestToken />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="*" element={<PrivateRoute><NotFound /></PrivateRoute>} />
      </Routes>
    </Suspense>

  );
};

export default RouteSetup;
